@import '../../../_rawlings/client/components/_variables.css';

.Layout.EastonLayout {
  background: white;
}

.Layout.EastonLayout .Layout-rendererBackground {
  background: white;
}

.Layout.EastonLayout .finalize-preview {
  background: white;
}

.Layout.EastonLayout .Layout-select {
  background: white;
}

.Layout.EastonLayout .button.mod-white {
  background: #cbcbcb;
}

.Layout.EastonLayout
  .button:not(.mod-white, .mod-gray, .mod-chrome, .mod-control) {
  background-color: var(--color-yellow);
  color: var(--color-darkerGray);
}

.Layout.EastonLayout
  .button:not(.mod-white, .mod-gray, .mod-chrome, .mod-control):hover {
  background-color: var(--color-purple);
  color: var(--color-white);
}

.Layout.EastonLayout
  .button:not(.mod-white, .mod-gray, .mod-chrome, .mod-control)
  .icon {
  fill: var(--color-darkerGray);
}
.Layout.EastonLayout
  .button:not(.mod-white, .mod-gray, .mod-chrome, .mod-control):hover
  .icon {
  fill: var(--color-white);
}

.Layout.EastonLayout .purchase-summaryLink {
  color: var(--color-blue);
}

.Layout.EastonLayout .purchase-summaryLink:hover {
  color: var(--color-blue);
}

.Layout.EastonLayout .purchase-summaryLink .icon {
  fill: var(--color-blue);
}

.Layout.EastonLayout .purchase-summaryLink:hover .icon {
  fill: var(--color-blue);
}

.Layout.EastonLayout .tile-select,
.Layout.EastonLayout .tileCard-checkmark {
  background-color: var(--color-yellow);
}
.Layout.EastonLayout .tile-select .icon,
.Layout.EastonLayout .tileCard-checkmark .icon {
  fill: var(--color-darkerGray);
}

.Layout.EastonLayout .spinner {
  border: 10px solid rgb(from var(--color-darkGray) r g b / 50%);
  border-top-color: var(--color-yellow);
}

.Layout.EastonLayout {
  .tile.mod-scroll-menu.mod-selected > div.tile-content:not(.mod-transparent) {
    border-color: var(--color-yellow);
  }
  .menu-bar .add-to-cart-button {
    background-color: var(--color-yellow);
    color: var(--color-darkerGray);
  }
}
